import Hero from "./hero/Hero";
import Location from "./location/Location";
import PropertyTypes from "./propertytypes/PropertyTypes";
import FeaturedProperties from "./featuredproperties/FeaturedProperties";
import FilterForm from "../filterform/FilterForm";
import "./Home.css";

const Home = () => {
  return (
    <>
      <Hero />
      <div className="filter-form-container">
        <FilterForm hideDeveloperTab heroStyle={true} />
      </div>
      <FeaturedProperties />
      <Location />
      <PropertyTypes />
    </>
  );
};

export default Home;
