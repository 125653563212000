import React from "react";
import img from "../../../logo.svg";

import Back from "../../headerfooter/Back";
import "./Contact.css";

const Contact = () => {
  return (
    <>
      <section className="contact mb">
        <Back
          // name="Contact Us"
          title="Contact Us"
          cover={img}
        />
        <div className="container contact-container">
          <div className="contact-info">
            <div className="contact-item">
              <i className="fa fa-phone"></i>
              <span>+92 321 841 9800</span>
            </div>
            <div className="contact-item">
              <i className="fa fa-envelope"></i>
              <span>info@kohinoorconsultants.com</span>
            </div>
            <div className="contact-item">
              <i className="fa fa-map-marker"></i>
              <span>
                Office #8 BF Defence Shopping Mall, D.H.A. Main Blvd, Iqbal
                Park, Lahore, Pakistan
              </span>
            </div>
          </div>
          <a
            href="https://api.whatsapp.com/send?phone=+923218419800"
            className="whatsapp-button"
          >
            WhatsApp Us
          </a>
          <div className="social-buttons">
            <a
              href="https://facebook.com/yourprofile"
              className="social-button facebook-button"
            >
              <i className="fa fa-facebook"></i>
            </a>
            <a
              href="https://instagram.com/yourprofile"
              className="social-button instagram-button"
            >
              <i className="fa fa-instagram"></i>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
