import React from "react";
import { featured } from "../../Data";

const PropertyTypesCard = () => {
  function replaceSpacesWithPlus(str) {
    return str.replace(/ /g, "+");
  }
  return (
    <>
      <div className="content grid5 mtop">
        {featured.map((items, index) => (
          <a
            href={`/projects?location=All&category=All&propertyType=${replaceSpacesWithPlus(
              items.name
            )}&developer=All&startingPrice=undefined&endingPrice=undefined`}
          >
            <div className="box" key={index}>
              <img src={items.cover} alt="" />
              <h4>{items.name}</h4>
              <label>{items.total}</label>
            </div>
          </a>
        ))}
      </div>
    </>
  );
};

export default PropertyTypesCard;
