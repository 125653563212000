export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "projects",
    path: "/projects",
  },
  {
    text: "about us",
    path: "/about",
  },

  {
    text: "contact",
    path: "/contact",
  },
]
export const featured = [
  {
    cover: "/pictures/featuredpropertytypes/h2.png",
    name: "Townhouses & Villas",
  },
  {
    cover: "/pictures/featuredpropertytypes/h3.png",
    name: "Apartments",
  },
  {
    cover: "/pictures/featuredpropertytypes/h6.png",
    name: "Penthouses",
  },
  {
    cover:"/pictures/featuredpropertytypes/h4.png",
    name: "Commercial Outlets"
  }
]
export const filterFormList = {
  locations:["Jumeirah Village Circle","Damac Hills 2","Jumeirah Lake Towers","Dubai Marina","Dubai Production City","Dubai South","Al Marjan Island"],
  categories:["For Sale","For Rent","Asset Ready for Possession"],
  propertyTypes:["Apartments","Townhouses","Commercial Properties"],
  developers:["Ellington","Damac","Danube","London Gate","Samana","Azizi"]
}
export const list = [
  {
    "id": 1,
    "cover": "/projectpictures/coverphotos/oceanpearl.jpg",
    "name": "Samana Ocean Pearl",
    "location": "Dubai Islands, Dubai, UAE",
    "category": "Under Construction",
    "developer": "Samana Developers",
    "price": "1600000",
    "propertyType": "Apartments",
    "currency": "AED",
    "projectPage": true
  },
  {
    "id": 2,
    "cover": "/projectpictures/coverphotos/DamacViolet3.jpg",
    "name": "Damac Violet 3",
    "location": "DAMAC Hills 2, Dubai, UAE",
    "highlight": "Upto 3 Golden Visas with every Unit with AED 2M T&C Apply",
    "category": "Under Construction",
    "developer": "Damac Properties",
    "price": "1911000",  // Update with specific pricing if available
    "propertyType": "Townhouses",
    "currency": "AED",
    "projectPage": true
  },
  {
    "id": 3,
    "cover": "/projectpictures/coverphotos/SamanaAvenue.webp",
    "name": "Samana Avenue at Dubai Land Residence Complex",
    "location": "Dubai Land Residence Complex, Dubai, UAE",
    "category": "For Sale",
    "developer": "Samana Developers",
    "price": "1100000",
    "propertyType": "Apartments",
    "currency": "AED",
    "projectPage": true
  },
  {
    "id": 4,
    "cover": "/projectpictures/coverphotos/hillmontellington.jpg",
    "name": "Hillmont Residencies",
    "location": "Jumeirah Village Circle, Dubai, UAE",
    "category": "Asset Ready for Possession",
    "developer": "Ellington",
    "price": "730828",
    "propertyType": "Apartments",
    "currency": "AED",
    "projectPage": true
  },
  {
    "id": 5,
    "cover": "/projectpictures/coverphotos/ArcResidences.webp",
    "name": "Arc Residences",
    "location": "Dubai Residence Complex, Dubailand, Dubai, UAE",
    "category": "Under Construction",
    "developer": "Aark Developers",
    "price": "819000",
    "propertyType": "Apartments",
    "currency": "AED",
    "projectPage": true
  },
  {
    "id": 6,
    "cover": "/projectpictures/coverphotos/diamondzdanube.jpg",
    "name": "Diamondz",
    "location": "Jumeirah Lake Towers, Dubai, UAE",
    "category": "Asset Ready for Possession",
    "developer": "Danube",
    "price": "1100000",
    "propertyType": "Apartments",
    "currency": "AED",
    "projectPage": true
  },
  {
    "id": 7,
    "cover": "/projectpictures/coverphotos/aeternitaslondongate.jpg",
    "name": "Aeternitas",
    "location": "Dubai Marina, Dubai, UAE",
    "category": "Asset Ready for Possession",
    "developer": "London Gate",
    "price": "1600000",
    "propertyType": "Apartments",
    "currency": "AED",
    "projectPage": true
  },
  {
    "id": 8,
    "cover": "/projectpictures/coverphotos/lakeviews2samana.webp",
    "name": "Lake Views 2",
    "location": "Dubai Production City, Dubai, UAE",
    "category": "Asset Ready for Possession",
    "developer": "Samana",
    "price": "639000",
    "propertyType": "Apartments",
    "currency": "AED",
    "projectPage": true
  },
  {
    "id": 9,
    "cover": "/projectpictures/coverphotos/veniceazizi.png",
    "name": "Azizi Venice",
    "location": "Dubai South, Dubai, UAE",
    "category": "Asset Ready for Possession",
    "developer": "Azizi Developments",
    "price": "640000",
    "propertyType": "Apartments",
    "currency": "AED",
    "projectPage": true
  },
  {
    "id": 10,
    "cover": "/projectpictures/coverphotos/caladelmarellington.webp",
    "name": "Cala Del Mar",
    "location": "Al Marjan Island, Ras Al Khaimah, UAE",
    "category": "Asset Ready for Possession",
    "developer": "Ellington",
    "price": "625000",
    "propertyType": "Apartments",
    "currency": "AED",
    "projectPage": true
  }
  
]
export const bigList = [
  {
    "id": 1,
    "images": [
        "../images/projects/samana/oceanpearl1.jpg",
        "../images/projects/samana/oceanpearl2.jpg",
        "../images/projects/samana/oceanpearl3.jpg",
        "../images/projects/samana/oceanpearl4.jpg"
    ],
    "cover": "../images/list/oceanpearl_project.jpg",
    "projectPicture": "/projectpictures/projectpagephotos/oceanpearl_project.webp",
    "name": "Samana Ocean Pearl",
    "description": "Located on the prestigious Dubai Islands, Samana Ocean Pearl offers a luxurious waterfront living experience with a range of 1, 2, 3, and 4-bedroom apartments. Each unit features high-quality materials and finishes, including European solid core doors and German matte black handles. Residents enjoy access to private pools, a state-of-the-art fitness center, fine dining, and proximity to pristine beaches and upscale shopping.",
    "location": "Dubai Islands, Deira, Dubai, UAE",
    "category": "Luxury Apartments",
    "developer": "Samana Developers",
    "price": "1600000",
    "type": "1, 2, 3 & 4 Bedroom Apartments",
    "currency": "AED",
    "Interior": [
        "/projectpictures/interior/oceanpearl_int_1.webp",
        "/projectpictures/interior/oceanpearl_int_2.webp",
        "/projectpictures/interior/oceanpearl_int_3.webp",
        "/projectpictures/interior/oceanpearl_int_4.webp"
    ],
    "Exterior": [
        "/projectpictures/exterior/oceanpearl_ext_1.jpg",
        "/projectpictures/exterior/oceanpearl_ext_2.webp"
    ],
    "Amenities": [
        { "icon": "swimmer", "label": "Swimming Pool" },
        { "icon": "spa", "label": "Spa & Sauna" },
        { "icon": "leaf", "label": "Landscaped Gardens" },
        { "icon": "shopping-cart", "label": "Retail Outlets" },
        { "icon": "dumbbell", "label": "Fitness Center" }
    ]
},
{
  "id": 2,
  "images": [
      "../images/projects/damac/violet3_1.jpg",
      "../images/projects/damac/violet3_2.jpg",
      "../images/projects/damac/violet3_3.jpg",
      "../images/projects/damac/violet3_4.jpg"
  ],
  "cover": "../images/list/DamacViolet3.jpg",
  "projectPicture": "/projectpictures/projectpagephotos/violet3_project.jpg",
  "name": "Damac Violet 3",
  "description": "Damac Violet 3 is located in Dubai and offers a range of luxurious villas with modern amenities and stunning city views. The project is developed by Damac Properties, known for their upscale developments. Residents enjoy access to swimming pools, gyms, retail outlets, and restaurants, creating a comprehensive luxury living experience.",
  "location": "DAMAC Hills 2, Dubai, UAE",
  "category": "Townhouses",
  "developer": "Damac Properties",
  "price": "1911000",  // Update with specific pricing if available
  "type": "Villas",
  "currency": "AED",
  "Interior": [
      "/projectpictures/interior/violet3_int_1.jpg",
      "/projectpictures/interior/violet3_int_2.jpg"
  ],
  "Exterior": [
      "/projectpictures/exterior/violet3_ext_1.webp",
      "/projectpictures/exterior/violet3_ext_2.png"
  ],
  "Amenities": [
      { "icon": "swimmer", "label": "Swimming Pool" },
      { "icon": "dumbbell", "label": "Gym" },
      { "icon": "shopping-cart", "label": "Retail Outlets" },
      { "icon": "utensils", "label": "Restaurants" },
      { "icon": "spa", "label": "Spa" }
  ]
},
{
  "id": 3,
  "images": [
      "../images/projects/samana/avenue1.jpg",
      "../images/projects/samana/avenue2.jpg",
      "../images/projects/samana/avenue3.jpg",
      "../images/projects/samana/avenue4.jpg"
  ],
  "cover": "../images/list/SamanaAvenue.webp",
  "projectPicture": "/projectpictures/projectpagephotos/samanaavenue_project.webp",
  "name": "Samana Avenue (DLRC)",
  "description": "Samana Avenue is located in the Dubai Land Residence Complex, offering a blend of luxury and comfort in a strategic location. The development features modern apartments with access to amenities like a swimming pool, gym, landscaped gardens, and retail outlets, making it an ideal choice for those seeking a balanced lifestyle in Dubai.",
  "location": "Dubai Land Residence Complex, Dubai, UAE",
  "category": "Luxury Apartments",
  "developer": "Samana Developers",
  "price": "1100000",
  "type": "Apartments",
  "currency": "AED",
  "Interior": [
      "/projectpictures/interior/avenue_int_1.webp",
      "/projectpictures/interior/avenue_int_2.jpg",
      "/projectpictures/interior/avenue_int_3.webp"
  ],
  "Exterior": [
      "/projectpictures/exterior/avenue_ext_1.png",
      "/projectpictures/exterior/avenue_ext_2.webp"
  ],
  "Amenities": [
      { "icon": "swimmer", "label": "Swimming Pool" },
      { "icon": "dumbbell", "label": "Gym" },
      { "icon": "leaf", "label": "Landscaped Gardens" },
      { "icon": "shopping-cart", "label": "Retail Outlets" },
      { "icon": "spa", "label": "Spa" }
  ]
},
{
    "id": 4,
    "images": [
        "../images/projects/ellington/hillmont1.jpg",
        "../images/projects/ellington/hillmont2.jpg",
        "../images/projects/ellington/hillmont3.jpg",
        "../images/projects/ellington/hillmont4.jpg"
    ],
    "cover": "../images/list/HillmontEllington.jpg",
    "projectPicture": "/projectpictures/projectpagephotos/hillmont_project.jpg",
    "name": "Hillmont Residences",
    "description":"This project is situated in Jumeirah Village Circle and offers a range of modern living options from studios up to three-bedroom apartments. The development includes luxury amenities such as a swimming pool, spa and sauna, community parks, and retail outlets. Each apartment embodies contemporary elegance with an emphasis on creating a vibrant community spirit and enhancing the quality of life",
    "location": "Jumeirah Village Circle, Dubai, UAE",
    "category": "Luxury Apartments",
    "developer": "Ellington Properties",
    "price": "730828",
    "type": "Studio, 1, 2 & 3 Bedroom Apartments",
    "currency": "AED",
    "Interior": [
        "/projectpictures/interior/hillmont_int_1.jpg",
        "/projectpictures/interior/hillmont_int_2.jpg",
        "/projectpictures/interior/hillmont_int_3.jpg"
    ],
    "Exterior": [
        "/projectpictures/exterior/hillmont_ext_2.jpg",
        "/projectpictures/exterior/hillmont_ext_3.jpg"
    ],
    "Amenities": [
        { "icon": "swimmer", "label": "Swimming Pool" },
        { "icon": "leaf", "label": "Community Parks" },
        { "icon": "spa", "label": "Spa & Sauna" },
        { "icon": "restaurant", "label": "Restaurants" },
        { "icon": "shopping-cart", "label": "Retail Outlets" }
    ]
},
{
  "id": 5,
  "images": [
      "../images/projects/aarkresidences/arcresidences1.jpg",
      "../images/projects/aarkresidences/arcresidences2.jpg",
      "../images/projects/aarkresidences/arcresidences3.jpg",
      "../images/projects/aarkresidences/arcresidences4.jpg"
  ],
  "cover": "../images/list/ArcResidences.webp",
  "projectPicture": "/projectpictures/projectpagephotos/arcresidences_project.png",
  "name": "Arc Residences",
  "description": "Located in the heart of Dubailand, Arc Residences by Aark Developers offers modern living with luxury 1 and 2 bedroom apartments. The 11-storey building features sleek interiors, state-of-the-art facilities, and sustainable design. With an infinity-edge pool, fitness center, and proximity to major attractions like Burj Khalifa and Dubai Outlet Mall, this development promises a blend of contemporary elegance and urban convenience.",
  "location": "Dubai Residence Complex, Dubailand, Dubai, UAE",
  "category": "Luxury Apartments",
  "developer": "Aark Developers",
  "price": "819000",
  "type": "1 & 2 Bedroom Apartments",
  "currency": "AED",
  "Interior": [
      "/projectpictures/interior/arcresidences_int_1.jpg",
      "/projectpictures/interior/arcresidences_int_2.jpg",
      "/projectpictures/interior/arcresidences_int_3.webp"
  ],
  "Exterior": [
      "/projectpictures/exterior/arcresidences_ext_1.jpg",
      "/projectpictures/exterior/arcresidences_ext_2.jpg"
  ],
  "Amenities": [
      { "icon": "swimmer", "label": "Infinity Pool" },
      { "icon": "dumbbell", "label": "Fitness Center" },
      { "icon": "spa", "label": "Shared Spa" },
      { "icon": "leaf", "label": "Landscaped Gardens" },
      { "icon": "bicycle", "label": "Cycle Track" }
  ]
},
        {
          "id": 6,
          "images": [
              "../images/projects/danube/diamondz1.jpg",
              "../images/projects/danube/diamondz2.jpg",
              "../images/projects/danube/diamondz3.jpg",
              "../images/projects/danube/diamondz4.jpg"
          ],
          "cover": "../images/list/DiamondzDanube.jpg",
          "projectPicture": "/projectpictures/projectpagephotos/diamondz_project.jpg",
          "name": "Diamondz",
          "description":"The Diamondz project features luxurious apartments with a variety of amenities, including an infinity pool, BBQ area, bowling center, spa, and fitness center, all designed to offer a high-end living experience in Jumeirah Lake Towers​",
          "location": "Jumeirah Lake Towers, Dubai, UAE",
          "category": "Luxury Apartments",
          "developer": "Danube Properties",
          "price": "1100000",
          "type": "Apartments with options including studios, 1BR, 1BR+POOL, 2BR+POOL, 3BR+POOL, 4BR+POOL",
          "currency": "AED",
          "Interior": [
              "/projectpictures/interior/diamondz_int_1.jpg",
              "/projectpictures/interior/diamondz_int_2.jpg",
              "/projectpictures/interior/diamondz_int_3.jpg",
          ],
          "Exterior": [
              "/projectpictures/exterior/diamondz_ext_1.jpg",
              "/projectpictures/exterior/diamondz_ext_2.jpg",
          ],
          "Amenities": [
              { "icon": "swimmer", "label": "Infinity Pool" },
              { "icon": "bbq", "label": "BBQ Area" },
              { "icon": "bowling", "label": "Bowling Center" },
              { "icon": "spa", "label": "Spa & Therapy Center" },
              { "icon": "gym", "label": "Fitness Center" }
          ]
      },{
    "id": 7,
    "images": [
        "../images/projects/london_gate/aeternitas1.jpg",
        "../images/projects/london_gate/aeternitas2.jpg",
        "../images/projects/london_gate/aeternitas3.jpg",
        "../images/projects/london_gate/aeternitas4.jpg"
    ],
    "cover": "../images/list/AeternitasLondonGate.jpg",
    "projectPicture": "/projectpictures/projectpagephotos/aeternitas_project1.jpg",
    "name": "Aeternitas",
    "description":"The Aeternitas project stands out as the world's tallest residential clock tower, branded by Franck Muller Watches, and offers a range of luxury apartments with spectacular views and a suite of premium amenities. This includes an infinity pool, yoga studio, spa, cigar lounge, and private cinema, all designed to provide a high-end lifestyle in Dubai Marina​ ",
    "location": "Dubai Marina, Dubai, UAE",
    "category": "Ultra Luxury Apartments",
    "developer": "London Gate",
    "price": "1600000",
    "type": "Apartments (1-4 Bedrooms)",
    "currency": "AED",
    "Interior": [
        "/projectpictures/interior/aeternitas_int_1.webp",
        "/projectpictures/interior/aeternitas_int_2.jpg",
        "/projectpictures/interior/aeternitas_int_3.jpg"
    ],
    "Exterior": [
        "/projectpictures/exterior/aeternitas_ext_1.jpg",
        "/projectpictures/exterior/aeternitas_ext_2.jpg",
        "/projectpictures/exterior/aeternitas_ext_3.jpg"
    ],
    "Amenities": [
        { "icon": "swimmer", "label": "Infinity Pool" },
        { "icon": "yoga", "label": "Yoga Studio" },
        { "icon": "spa", "label": "Spa & Therapy Center" },
        { "icon": "cigar", "label": "Cigar Lounge" },
        { "icon": "cinema", "label": "Private Cinema" }
    ]
},{
  "id": 8,
  "images": [
      "../images/projects/samana/lake_views_2_1.jpg",
      "../images/projects/samana/lake_views_2_2.jpg",
      "../images/projects/samana/lake_views_2_3.jpg",
      "../images/projects/samana/lake_views_2_4.jpg"
  ],
  "cover": "../images/list/LakeViews2Samana.jpg",
  "projectPicture": "/projectpictures/projectpagephotos/lakeviews2_project.webp",
  "name": "Lake Views 2",
  "description":"The Lake Views 2 project offers a range of luxury apartments with private pools, fitness centers, landscaped gardens, barbecue areas, and even a dine-in cinema. It's located in Dubai Production City, a location known for its connectivity and vibrant community​",
  "location": "Dubai Production City, Dubai, UAE",
  "category": "Luxury Apartments",
  "developer": "Samana Developers",
  "price": "639000",
  "type": "Studios, 1 & 2 Bedroom Apartments",
  "currency": "AED",
  "Interior": [
      "/projectpictures/interior/lake_views_2_int_1.jpg",
      "/projectpictures/interior/lake_views_2_int_2.webp",
      "/projectpictures/interior/lake_views_2_int_3.jpg"
  ],
  "Exterior": [
      "/projectpictures/exterior/lake_views_2_ext_1.jpeg",
      "/projectpictures/exterior/lake_views_2_ext_2.webp"
  ],
  "Amenities": [
      { "icon": "swimmer", "label": "Private Pool" },
      { "icon": "dumbbell", "label": "Fitness Center" },
      { "icon": "tree", "label": "Landscaped Gardens" },
      { "icon": "bbq", "label": "Barbecue Area" },
      { "icon": "cinema", "label": "Dine-in Cinema" }
  ]
},{
  "id": 9,
  "images": [
      "../images/projects/azizi/venice1.jpg",
      "../images/projects/azizi/venice2.jpg",
      "../images/projects/azizi/venice3.jpg",
      "../images/projects/azizi/venice4.jpg"
  ],
  "cover": "../images/list/VeniceAzizi.jpg",
  "projectPicture": "/projectpictures/projectpagephotos/venice_project.jpg",
  "name": "Azizi Venice",
  "description":"The Venice project is designed to emulate a Venetian experience with luxury waterfront living in Dubai South. It offers a range of studios and apartments, with a unique selling point of its 18 km sweet-water lagoon that includes artificial waves. Residents can enjoy a host of amenities including an infinity pool, gymnasium, children’s play area, and barbecue area​",
  "location": "Dubai South, Dubai, UAE",
  "category": "Luxury Waterfront Apartments",
  "developer": "Azizi Developments",
  "price": "640000",
  "type": "Studios, 1, 2 & 3 Bedroom Apartments and Villas",
  "currency": "AED",
  "Interior": [
      "/projectpictures/interior/venice_int_1.jpg",
      "/projectpictures/interior/venice_int_2.webp",
      "/projectpictures/interior/venice_int_3.jpg"
  ],
  "Exterior": [
      "/projectpictures/exterior/venice_ext_1.webp",
      "/projectpictures/exterior/venice_ext_2.webp",
      "/projectpictures/exterior/venice_ext_3.webp"
  ],
  "Amenities": [
      { "icon": "restaurant", "label": "Restaurants" },
      { "icon": "pool", "label": "Infinity Pool" },
      { "icon": "gym", "label": "Gymnasium" },
      { "icon": "playground", "label": "Children's Play Area" },
      { "icon": "bbq", "label": "Barbecue Area" }
  ]
},
{
  "id": 10,
  "images": [
      "../images/projects/ellington/cala_del_mar1.jpg",
      "../images/projects/ellington/cala_del_mar2.jpg",
      "../images/projects/ellington/cala_del_mar3.jpg",
      "../images/projects/ellington/cala_del_mar4.jpg"
  ],
  "cover": "../images/list/CalaDelMarEllington.jpg",
  "projectPicture": "/projectpictures/projectpagephotos/caladelmar_project.jpg",
  "name": "Cala Del Mar",
  "description":"The Cala Del Mar project offers a luxurious waterfront lifestyle with apartments ranging from studios to four bedrooms, all featuring stunning water views. It's set on the scenic Al Marjan Island in Ras Al Khaimah and includes amenities like an infinity pool, a state-of-the-art gym, and green surroundings, enhancing the living experience",
  "location": "Al Marjan Island, Ras Al Khaimah, UAE",
  "category": "Luxury Waterfront Apartments",
  "developer": "Ellington Properties",
  "price": "625000",
  "type": "Studios, 1, 2, 3 & 4 Bedroom Apartments",
  "currency": "AED",
  "Interior": [
      "/projectpictures/interior/caladelmar_int_1.jpg",
      "/projectpictures/interior/caladelmar_int_2.jpg",
      "/projectpictures/interior/caladelmar_int_3.jpg"
  ],
  "Exterior": [
      "/projectpictures/exterior/caladelmar_ext_1.jpg",
      "/projectpictures/exterior/caladelmar_ext_2.webp",
      "/projectpictures/exterior/caladelmar_ext_3.jpg"
  ],
  "Amenities": [
      { "icon": "swimmer", "label": "Infinity Pool" },
      { "icon": "dumbbell", "label": "Gymnasium" },
      { "icon": "leaf", "label": "Green Surrounding" },
      { "icon": "restaurant", "label": "Restaurants" },
      { "icon": "shopping-cart", "label": "Retail Outlets" }
  ]
},      
]
export const location = [
  {
    id: 1,
    name: "Marina, Dubai",
    path:"Dubai+Marina",
    cover: "/pictures/locations/DubaiMarina.webp",
  },
  {
    id: 2,
    name: "Jumeirah Village Circle, Dubai",
    path:"Jumeirah+Village+Circle",
    cover: "/pictures/locations/jumeirahvillagecircle.webp",
  },
  {
    id: 3,
    name: "Damac Hills 2, Dubai",
    path:"Damac+Hills+2",
    cover: "/pictures/locations/damachills2.webp",
  },
  {
    id: 4,
    name: "Dubai South, Dubai",
    path:"Dubai+South",
    cover: "/pictures/locations/dubaisouth.jpg",
  },
  {
    id: 5,
    name: "Jumeirah Lake Towers, Dubai",
    path:"Jumeirah+Lake+Towers",
    cover: "/pictures/locations/jumeirahlaketowers.jpg",
  },
  {
    id: 6,
    name: "Dubai Production City, Dubai",
    path:"Dubai+Production+City",
    cover: "/pictures/locations/dubaiproductioncity.jpg",
  },
  {
    id: 7,
    name: "Al Marjan Island, Dubai",
    path:"Al+Marjan+Island",
    cover: "/pictures/locations/almarjanisland.jpg",
  },
]
export const footer = [
  {
    title: "LAYOUTS",
    text: [{ list: "Home Page" }, { list: "About Page" }, { list: "Projects" }, { list: "Contact Us" }],
  },
  // {
  //   title: "ALL SECTIONS",
  //   text: [{ list: "Headers" }, { list: "Features" }, { list: "Attractive" }, { list: "Testimonials" }, { list: "Videos" }, { list: "Footers" }],
  // },
  // {
  //   title: "COMPANY",
  //   text: [{ list: "About" }, { list: "Blog" }, { list: "Pricing" }, { list: "Affiliate" }, { list: "Login" }, { list: "Changelog" }],
  // },
]