// CardComponent.jsx
import React from "react";
import { list } from "../Data";
import { Link } from "react-router-dom";
import "./PropertyCard.css";

const PropertyCard = (props) => {
  return (
    <div className="content">
      {list.map((val, index) => {
        const {
          id,
          cover,
          category,
          location,
          name,
          price,
          propertyType,
          currency,
          projectPage,
          highlight,
          developer,
        } = val;
        const projectId = id;
        const locationFilter =
          location.includes(props.locationFilter) ||
          props.locationFilter === "All";
        console.log(props.locationFilter.includes(location));
        const categoryFilter =
          props.categoryFilter === category || props.categoryFilter === "All";
        const developerFilter =
          props.developerFilter === developer ||
          props.developerFilter === "All";
        const propertyTypeFilter =
          props.propertyTypeFilter === propertyType ||
          props.propertyTypeFilter === "All";
        const priceFilter =
          (props.minPrice <= price && props.maxPrice >= price) ||
          (props.minPrice === -1 && props.maxPrice === -1) ||
          (props.minPrice === -1 && props.maxPrice >= price) ||
          (props.minPrice <= price && props.maxPrice === -1) ||
          (!props.minPrice && !props.maxPrice);

        return (
          locationFilter &&
          categoryFilter &&
          developerFilter &&
          priceFilter &&
          propertyTypeFilter && (
            <div className="card" key={index}>
              <img src={cover} alt={name} className="card-img" />
              <div className="card-content">
                <div
                  className="category-label"
                  style={{
                    background:
                      category === "Asset Ready for Possession"
                        ? "#25b5791a"
                        : "#ff98001a",
                    color:
                      category === "Asset Ready for Possession"
                        ? "#25b579"
                        : "#ff9800",
                  }}
                >
                  {category}
                </div>
                <h5 className="card-title">{name}</h5>
                <p className="card-info">
                  <i className="fa fa-location-dot"></i> {location}
                </p>
                {highlight && (
                  <div className="highlight">
                    <i className="fa fa-star"></i> {highlight}
                  </div>
                )}
                <div className="card-type">{propertyType}</div>
              </div>
              <div className="card-footer">
                <p className="price">
                  <div className="starting-from">Starting from </div>
                  {currency} {Number(price).toLocaleString("en-US")}
                </p>
                {projectPage && (
                  <Link to={`/projectPage/${projectId}`}>
                    <button className="btn btn-large">View Details</button>
                  </Link>
                )}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default PropertyCard;
