import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Back from "../headerfooter/Back";
import PropertyCard from "../card/PropertyCard";
//import img from "../pictures/dubaiskyline.jpg";
import FilterForm from "../filterform/FilterForm";
import "./Projects.css";

const Projects = () => {
  const [locationFilter, setLocationFilter] = useState("All");
  const [categoryFilter, setCategoryFilter] = useState("All");
  const [propertyTypeFilter, setPropertyTypeFilter] = useState("All");
  const [developerFilter, setDeveloperFilter] = useState("All");
  const [minPrice, setMinPrice] = useState(-1);
  const [maxPrice, setMaxPrice] = useState(-1);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setLocationFilter(searchParams.get("location") || "All");
    setCategoryFilter(searchParams.get("category") || "All");
    setPropertyTypeFilter(searchParams.get("propertyType") || "All");
    setDeveloperFilter(searchParams.get("developer") || "All");
    setMinPrice(parseInt(searchParams.get("startingPrice"), 10) || -1);
    setMaxPrice(parseInt(searchParams.get("endingPrice"), 10) || -1);
  }, [location.search]);

  return (
    <>
      <Back
        title="Our Affiliated Projects"
        cover={"/pictures/dubaiskyline.jpg"}
      />
      <div className="filter-form-container">
        <FilterForm
          initialValues={{
            initialLocation: locationFilter,
            initialCategory: categoryFilter,
            initialPropertyType: propertyTypeFilter,
            initialDeveloper: developerFilter,
            initialStartingPrice: minPrice !== -1 ? minPrice.toString() : "",
            initialEndingPrice: maxPrice !== -1 ? maxPrice.toString() : "",
          }}
        />
      </div>
      <div className="container recent">
        <PropertyCard
          locationFilter={locationFilter}
          categoryFilter={categoryFilter}
          propertyTypeFilter={propertyTypeFilter}
          developerFilter={developerFilter}
          minPrice={minPrice}
          maxPrice={maxPrice}
          heroStyle={false}
        />
      </div>
    </>
  );
};

export default Projects;
