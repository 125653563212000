import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "./Amenities.css"; // Make sure to import the CSS file

library.add(fas);

const Amenities = ({ amenities }) => {
  return (
    <div className="unitsAmenities">
      <hr className="topLine" />
      <h2>Amenities</h2>
      <div className="amenitiesList">
        {amenities.map((amenity, index) => (
          <div className="amenityItem" key={index}>
            <FontAwesomeIcon icon={["fas", amenity.icon]} />
            <span className="amenityLabel">{amenity.label}</span>
          </div>
        ))}
      </div>
      <hr className="bottomLine" />
    </div>
  );
};

export default Amenities;
