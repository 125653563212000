import React from "react";
import Header from "./headerfooter/header/Header";
import Footer from "./headerfooter/footer/Footer";
import Home from "./home/Home";
import Projects from "./projects/Projects";
import Contact from "./home/contact/Contact";
import ProjectPage from "./projectpage/ProjectPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import pakmap from "./pakistanmap/pakmap";

const RouterPage = () => {
  return (
    <>
      <a
        href="https://wa.me/+923218419800"
        className="whatsapp-float"
        target="_blank"
      >
        <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
      </a>

      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/about" component={About} /> */}
          {/* <Route exact path="/services" component={Services} /> */}
          <Route exact path="/projects" component={Projects} />
          {/* <Route exact path='/pricing' component={Pricing} /> */}
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/projectPage/:projectId" component={ProjectPage} />
          <Route exact path="/map" component={pakmap} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
};

export default RouterPage;
