// ProjectPage.jsx
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { bigList } from "../Data";

import PropertyTitleRow from "./subcomponents/PropertyTitleRow";
import Amenities from "./subcomponents/Amenities";
import MySlider from "./subcomponents/MySlider";
import "./ProjectPage.css";

const ProjectPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { projectId } = useParams();
  const project = bigList.find((item) => item.id === parseInt(projectId, 10));

  if (!project) {
    console.error(`Project with ID ${projectId} not found.`);
    return <p>Project not found.</p>;
  }

  return (
    <>
      <div className="project-picture-container">
        <img
          className="project-picture"
          src={project.projectPicture}
          alt="project_picture "
        />
      </div>
      <div className="project-page">
        <PropertyTitleRow
          name={project.name}
          price={project.price}
          currency={project.currency}
          location={project.location}
        />
        {/* Render Amenities */}
        {project.Amenities && <Amenities amenities={project.Amenities} />}
        <span className="gallery-sliders">
          <MySlider pictureArray={project.Interior} title="Interior" />
          <MySlider pictureArray={project.Exterior} title="Exterior" />
        </span>
        {/* Other sections like PaymentPlan can be added here */}

        {/* Rest of the page content */}
      </div>
    </>
  );
};

export default ProjectPage;
