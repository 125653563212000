import PakistanMap from "./PakistanMap";
// import pakistanGeoJson from "./geoBoundaries-PAK-ADM1_simplified.geojson"; // Update the path accordingly
import React from "react";

export default function pakmap() {
  return (
    <div>
      <h1>Map of Pakistan</h1>
      <PakistanMap />
    </div>
  );
}
