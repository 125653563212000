import './App.css';
import RouterPage from './components/RouterPage';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  return (
    <>
    <SpeedInsights/>
    <Analytics/>
    <RouterPage />
    </>
  )
}

export default App;
