//Recent.jsx
import React from "react";
import "./FeaturedProperties.css";
import Heading from "../../headerfooter/Heading";
import PropertyCard from "../../card/PropertyCard";

const FeaturedProperties = () => {
  return (
    <>
      <section className="recent padding">
        <div className="container">
          <div className="property-heading">
            <Heading
              title="Our Best Selling Properties"
              subtitle={" "}
              // subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
            />
          </div>
          <PropertyCard
            locationFilter="All"
            categoryFilter="All"
            propertyTypeFilter="All"
            developerFilter="All"
            minPrice={-1}
            maxPrice={-1}
          />
        </div>
      </section>
    </>
  );
};

export default FeaturedProperties;
