import React, { useState, useEffect } from "react";
import "./FilterForm.css";
import { filterFormList } from "../Data";
import { useHistory } from "react-router-dom";

const FilterForm = ({
  onFilter,
  hideDeveloperTab,
  heroStyle,
  initialValues,
}) => {
  const [location, setLocation] = useState("All");
  const [category, setCategory] = useState("All");
  const [propertyType, setPropertyType] = useState("All");
  const [developer, setDeveloper] = useState("All"); // Add developer state
  const [startingPrice, setStartingPrice] = useState("");
  const [endingPrice, setEndingPrice] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (initialValues) {
      const {
        initialLocation,
        initialCategory,
        initialPropertyType,
        initialDeveloper,
        initialStartingPrice,
        initialEndingPrice,
      } = initialValues;

      setLocation(initialLocation || "All");
      setCategory(initialCategory || "All");
      setPropertyType(initialPropertyType || "All");
      setDeveloper(initialDeveloper || "All");
      setStartingPrice(initialStartingPrice || "");
      setEndingPrice(initialEndingPrice || "");
    }
  }, [initialValues]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const queryParams = new URLSearchParams({
      location,
      category,
      propertyType,
      developer,
      startingPrice: startingPrice || undefined, // Leave out if empty
      endingPrice: endingPrice || undefined,
    }).toString();
    history.push(`/projects?${queryParams}`); // Navigate with parameters
  };

  const formClass = heroStyle ? "flex-hero-form" : "flex-filter-form";
  const boxClass = heroStyle ? "hero-box box" : "filter-box box";

  return (
    <form className={formClass} onSubmit={handleSubmit}>
      <div className={boxClass}>
        <span>Location</span>
        <select value={location} onChange={(e) => setLocation(e.target.value)}>
          <option value="All">All Locations</option>
          {filterFormList.locations.map((loc) => (
            <option key={loc} value={loc}>
              {loc}
            </option>
          ))}
        </select>
      </div>

      <div className={boxClass}>
        <span>Category</span>
        <select value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="All">All Categories</option>
          {filterFormList.categories.map((cat) => (
            <option key={cat} value={cat}>
              {cat}
            </option>
          ))}
        </select>
      </div>

      <div className={boxClass}>
        <span>Property Type</span>
        <select
          value={propertyType}
          onChange={(e) => setPropertyType(e.target.value)}
        >
          <option value="All">All Types</option>
          {filterFormList.propertyTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>

      {!hideDeveloperTab && (
        <div className={boxClass}>
          <span>Developer</span>
          <select
            value={developer}
            onChange={(e) => setDeveloper(e.target.value)} // Add setDeveloper
          >
            <option value="All">All Developers</option>
            {filterFormList.developers &&
              filterFormList.developers.map((dev) => (
                <option key={dev} value={dev}>
                  {dev}
                </option>
              ))}
          </select>
        </div>
      )}

      <div className={boxClass}>
        <span>Starting Price</span>
        <input
          type="text"
          placeholder="Starting Price"
          value={startingPrice}
          onChange={(e) => setStartingPrice(e.target.value)}
        />
      </div>

      <div className={boxClass}>
        <span>Ending Price</span>
        <input
          type="text"
          placeholder="Ending Price"
          value={endingPrice}
          onChange={(e) => setEndingPrice(e.target.value)}
        />
      </div>

      <button className="btn1" type="submit">
        <i className="fa fa-search"></i> Search
      </button>
    </form>
  );
};

export default FilterForm;
