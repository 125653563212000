import React from "react";
import Heading from "../../headerfooter/Heading";
import { location as locationData } from "../../Data"; // renamed to avoid naming conflict
import "./Location.css";

const Location = () => {
  // Function to create location search string
  return (
    <>
      <section className="location padding">
        <div className="container">
          <Heading title="Explore By Dubai's Locations" />
          <div className="content grid3 mtop">
            {locationData.map((item, index) => (
              <div className="box" key={index}>
                <img src={item.cover} alt={item.name} />
                <a
                  href={`/projects?location=${item.path}&category=All&propertyType=All&developer=All&startingPrice=undefined&endingPrice=undefined`}
                >
                  <div className="overlay">
                    <h5>{item.name}</h5>
                    <p>
                      <label>{item.Villas}</label>
                      <label>{item.Offices}</label>
                      <label>{item.Apartments}</label>
                    </p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Location;
