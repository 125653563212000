// PropertyTitleRow.jsx
import React from "react";
import "./PropertyTitleRow.css"; // Import the corresponding CSS file

const PropertyTitleRow = ({ name, price, currency, location }) => {
  return (
    <div className="property-title-row">
      <div className="title-block">
        <div className="subdivision-title">{location}</div>
        <h1>{name}</h1>
      </div>
      <div className="price-block">
        <div className="price-details">
          <span className="price-title">Starting Price</span>
          <span className="currency">{currency} </span>
          <span className="price">{Number(price).toLocaleString("en-US")}</span>
        </div>
        <a href="https://wa.me/+923218419800" className="book-button">
          Learn More
        </a>
      </div>
    </div>
  );
};

export default PropertyTitleRow;
