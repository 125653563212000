//Footer.jsx
import React from "react";
import { nav } from "../../Data";

import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section className="footerContact">
        <div className="container">
          <div className="send flex">
            <div className="text">
              <h1>Do You Have Questions ?</h1>
              <p>Request a Callback!</p>
            </div>
            <a href="https://wa.me/+923218419800" target="_blank">
              <button className="btn5">Contact Us</button>
            </a>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="box">
            <div className="logo-and-name-footer">
              <div className="logo">
                <img src="/logo.png" alt="" />
              </div>
              <h1 className="company-name">Kohinoor Properties</h1>
            </div>
          </div>

          <div className="nav">
            <ul className="nav-list">
              {nav.map((item) => (
                <li key={item.text}>
                  <Link to={item.path}>{item.text}</Link>
                </li>
              ))}
            </ul>
          </div>

          {/* {footer.map((val) => (
            <div className="box">
              <ul>
                {val.text.map((items) => (
                  <li> {items.list} </li>
                ))}
              </ul>
            </div>
          ))} */}
        </div>
      </footer>
      <div className="legal">
        <span>© 2024 Site Created by Ali Asghar Hussain.</span>
      </div>
    </>
  );
};

export default Footer;
