// MySlider.jsx
import React from "react";
import { useState } from "react";
import "./MySlider.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan, faLessThan } from "@fortawesome/free-solid-svg-icons";

export default function MySlider(props) {
  const [pictureIndex, setPictureIndex] = useState(0);

  function handleRightArrowClick() {
    setPictureIndex((pictureIndex + 1) % props.pictureArray.length);
  }

  function handleLeftArrowClick() {
    setPictureIndex(
      (pictureIndex - 1 + props.pictureArray.length) % props.pictureArray.length
    );
  }

  return (
    <div id="my-slider">
      {props.title && <h2>{props.title}</h2>}
      <button
        id="left-arrow"
        onClick={handleLeftArrowClick}
        aria-label="Previous image"
      >
        <FontAwesomeIcon icon={faLessThan} />
      </button>
      <button
        id="right-arrow"
        onClick={handleRightArrowClick}
        aria-label="Next image"
      >
        <FontAwesomeIcon icon={faGreaterThan} />
      </button>
      <div
        className="slide-container"
        style={{ transform: `translateX(-${pictureIndex * 100}%)` }}
      >
        {props.pictureArray.map((src, index) => (
          <img key={index} src={src} alt={`Slide ${index}`} />
        ))}
      </div>
    </div>
  );
}
