//Header.jsx
import React, { useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";

import { nav } from "../../Data";

const Header = () => {
  const [navList, setNavList] = useState(false);

  return (
    <header>
      <div className="container flex">
        <div className="logo-and-name">
          <div className="logo">
            <img src="./logo.png" alt="logo" />
          </div>
          <h1 className="company-name">Kohinoor Properties</h1>
        </div>
        <div className="nav">
          <ul className={navList ? "nav-list small" : "nav-list"}>
            {nav.map((list, index) => (
              <li key={index}>
                <Link to={list.path}>{list.text}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="toggle">
          <button onClick={() => setNavList(!navList)}>
            {navList ? (
              <i className="fa fa-times"></i>
            ) : (
              <i className="fa fa-bars"></i>
            )}
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
