// Hero.jsx
import React from "react";
import "./Hero.css";
import { useHistory } from "react-router-dom";
import FilterForm from "../../filterform/FilterForm";

const Hero = () => {
  const history = useHistory();

  const handleFilter = (filters) => {
    // Route to "/blog" page and pass filters as state
    history.push({
      pathname: "/projects",
      state: filters,
    });
  };

  return (
    <section className="hero">
      <video autoPlay loop muted className="hero-video">
        <source src="/videos/visit_dubai_outdoors.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* <div className="filter-form-container-hero">
        <FilterForm onFilter={handleFilter} hideDeveloperTab heroStyle={true} />
      </div> */}
    </section>
  );
};

export default Hero;
