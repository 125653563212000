import React from "react";

import Heading from "../../headerfooter/Heading";
import "./PropertyTypes.css";
import FeaturedCard from "./PropertyTypesCard";

import PropertyTypesCard from "./PropertyTypesCard";

const PropertyTypes = () => {
  return (
    <>
      <section className="featured background">
        <div className="container">
          <Heading
            title="Featured Property Types"
            subtitle="Find All Type of Property."
          />
          <PropertyTypesCard />
        </div>
      </section>
    </>
  );
};

export default PropertyTypes;
